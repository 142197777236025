exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-build-vs-buy-tsx": () => import("./../../../src/pages/build-vs-buy.tsx" /* webpackChunkName: "component---src-pages-build-vs-buy-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-early-access-tsx": () => import("./../../../src/pages/early-access.tsx" /* webpackChunkName: "component---src-pages-early-access-tsx" */),
  "component---src-pages-enterprise-terms-tsx": () => import("./../../../src/pages/enterprise-terms.tsx" /* webpackChunkName: "component---src-pages-enterprise-terms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

